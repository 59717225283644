.ct-double-octave:after,
.ct-major-eleventh:after,
.ct-major-second:after,
.ct-major-seventh:after,
.ct-major-sixth:after,
.ct-major-tenth:after,
.ct-major-third:after,
.ct-major-twelfth:after,
.ct-minor-second:after,
.ct-minor-seventh:after,
.ct-minor-sixth:after,
.ct-minor-third:after,
.ct-octave:after,
.ct-perfect-fifth:after,
.ct-perfect-fourth:after,
.ct-square:after {
    content: "";
    clear: both;
}
.ct-label {
    fill: rgba(0, 0, 0, 0.4);
    color: rgba(0, 0, 0, 0.4);
    font-size: 0.75rem;
    line-height: 1;
}
.ct-grid-background,
.ct-line {
    fill: none;
}
.ct-chart-bar .ct-label,
.ct-chart-line .ct-label {
    display: flex;
}
.ct-chart-donut .ct-label,
.ct-chart-pie .ct-label {
    dominant-baseline: central;
}
.ct-label.ct-vertical.ct-start {
    -webkit-box-align: flex-end;
    -webkit-align-items: flex-end;
    -ms-flex-align: flex-end;
    align-items: flex-end;
    -webkit-box-pack: flex-end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: flex-end;
    justify-content: flex-end;
    text-align: right;
    text-anchor: end;
}
.ct-chart-bar .ct-label.ct-horizontal.ct-start {
    -webkit-box-align: flex-end;
    -webkit-align-items: flex-end;
    -ms-flex-align: flex-end;
    align-items: flex-end;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    text-anchor: start;
}
.ct-chart-bar .ct-label.ct-horizontal.ct-end {
    -webkit-box-align: flex-start;
    -webkit-align-items: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    text-anchor: start;
}
.ct-chart-bar.ct-horizontal-bars .ct-label.ct-horizontal.ct-start,
.ct-label.ct-vertical.ct-end,
.ct-label.ct-horizontal.ct-start {
    -webkit-box-align: flex-end;
    -webkit-align-items: flex-end;
    -ms-flex-align: flex-end;
    align-items: flex-end;
    -webkit-box-pack: flex-start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
    text-align: left;
    text-anchor: start;
}
.ct-chart-bar.ct-horizontal-bars .ct-label.ct-horizontal.ct-end,
.ct-label.ct-horizontal.ct-end {
    -webkit-box-align: flex-start;
    -webkit-align-items: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
    -webkit-box-pack: flex-start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
    text-align: left;
    text-anchor: start;
}
.ct-chart-bar.ct-horizontal-bars .ct-label.ct-vertical.ct-start {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: flex-end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: flex-end;
    justify-content: flex-end;
    text-align: right;
    text-anchor: end;
}
.ct-chart-bar.ct-horizontal-bars .ct-label.ct-vertical.ct-end {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: flex-start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
    text-align: left;
    text-anchor: end;
}
.ct-point {
    stroke-width: 10px;
    stroke-linecap: round;
}
.ct-line {
    stroke-width: 4px;
}
.ct-area {
    stroke: none;
    fill-opacity: 0.1;
}
.ct-bar {
    fill: none;
    stroke-width: 10px;
}
.ct-slice-donut {
    fill: none;
    stroke-width: 60px;
}
.ct-series-a .ct-bar,
.ct-series-a .ct-line,
.ct-series-a .ct-point,
.ct-series-a .ct-slice-donut {
    stroke: #d70206;
}
.ct-series-a .ct-area,
.ct-series-a .ct-slice-donut-solid,
.ct-series-a .ct-slice-pie {
    fill: #d70206;
}
.ct-series-c .ct-bar,
.ct-series-c .ct-line,
.ct-series-c .ct-point,
.ct-series-c .ct-slice-donut {
    stroke: #f4c63d;
}
.ct-series-c .ct-area,
.ct-series-c .ct-slice-donut-solid,
.ct-series-c .ct-slice-pie {
    fill: #f4c63d;
}
.ct-series-d .ct-bar,
.ct-series-d .ct-line,
.ct-series-d .ct-point,
.ct-series-d .ct-slice-donut {
    stroke: #d17905;
}
.ct-series-d .ct-area,
.ct-series-d .ct-slice-donut-solid,
.ct-series-d .ct-slice-pie {
    fill: #d17905;
}
.ct-series-e .ct-bar,
.ct-series-e .ct-line,
.ct-series-e .ct-point,
.ct-series-e .ct-slice-donut {
    stroke: #453d3f;
}
.ct-series-e .ct-area,
.ct-series-e .ct-slice-donut-solid,
.ct-series-e .ct-slice-pie {
    fill: #453d3f;
}
.ct-series-f .ct-bar,
.ct-series-f .ct-line,
.ct-series-f .ct-point,
.ct-series-f .ct-slice-donut {
    stroke: #59922b;
}
.ct-series-f .ct-area,
.ct-series-f .ct-slice-donut-solid,
.ct-series-f .ct-slice-pie {
    fill: #59922b;
}
.ct-series-g .ct-bar,
.ct-series-g .ct-line,
.ct-series-g .ct-point,
.ct-series-g .ct-slice-donut {
    stroke: #0544d3;
}
.ct-series-g .ct-area,
.ct-series-g .ct-slice-donut-solid,
.ct-series-g .ct-slice-pie {
    fill: #0544d3;
}
.ct-series-h .ct-bar,
.ct-series-h .ct-line,
.ct-series-h .ct-point,
.ct-series-h .ct-slice-donut {
    stroke: #6b0392;
}
.ct-series-h .ct-area,
.ct-series-h .ct-slice-donut-solid,
.ct-series-h .ct-slice-pie {
    fill: #6b0392;
}
.ct-series-i .ct-bar,
.ct-series-i .ct-line,
.ct-series-i .ct-point,
.ct-series-i .ct-slice-donut,
.ct-series-b .ct-bar,
.ct-series-b .ct-line,
.ct-series-b .ct-point,
.ct-series-b .ct-slice-donut {
    stroke: #f05b4f;
}
.ct-series-i .ct-area,
.ct-series-i .ct-slice-donut-solid,
.ct-series-i .ct-slice-pie,
.ct-series-b .ct-area,
.ct-series-b .ct-slice-donut-solid,
.ct-series-b .ct-slice-pie {
    fill: #f05b4f;
}
.ct-series-j .ct-bar,
.ct-series-j .ct-line,
.ct-series-j .ct-point,
.ct-series-j .ct-slice-donut {
    stroke: #dda458;
}
.ct-series-j .ct-area,
.ct-series-j .ct-slice-donut-solid,
.ct-series-j .ct-slice-pie {
    fill: #dda458;
}
.ct-series-k .ct-bar,
.ct-series-k .ct-line,
.ct-series-k .ct-point,
.ct-series-k .ct-slice-donut {
    stroke: #eacf7d;
}
.ct-series-k .ct-area,
.ct-series-k .ct-slice-donut-solid,
.ct-series-k .ct-slice-pie {
    fill: #eacf7d;
}
.ct-series-l .ct-bar,
.ct-series-l .ct-line,
.ct-series-l .ct-point,
.ct-series-l .ct-slice-donut {
    stroke: #86797d;
}
.ct-series-l .ct-area,
.ct-series-l .ct-slice-donut-solid,
.ct-series-l .ct-slice-pie {
    fill: #86797d;
}
.ct-series-m .ct-bar,
.ct-series-m .ct-line,
.ct-series-m .ct-point,
.ct-series-m .ct-slice-donut {
    stroke: #b2c326;
}
.ct-series-m .ct-area,
.ct-series-m .ct-slice-donut-solid,
.ct-series-m .ct-slice-pie {
    fill: #b2c326;
}
.ct-series-n .ct-bar,
.ct-series-n .ct-line,
.ct-series-n .ct-point,
.ct-series-n .ct-slice-donut {
    stroke: #6188e2;
}
.ct-series-n .ct-area,
.ct-series-n .ct-slice-donut-solid,
.ct-series-n .ct-slice-pie {
    fill: #6188e2;
}
.ct-series-o .ct-bar,
.ct-series-o .ct-line,
.ct-series-o .ct-point,
.ct-series-o .ct-slice-donut {
    stroke: #a748ca;
}
.ct-series-o .ct-area,
.ct-series-o .ct-slice-donut-solid,
.ct-series-o .ct-slice-pie {
    fill: #a748ca;
}
.ct-square:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 100%;
}
.ct-minor-second:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 93.75%;
}
.ct-major-second:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 88.8888888889%;
}
.ct-minor-third:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 83.3333333333%;
}
.ct-major-third:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 80%;
}
.ct-perfect-fourth:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 75%;
}
.ct-perfect-fifth:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 66.6666666667%;
}
.ct-minor-sixth:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 62.5%;
}
.ct-golden-section:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 61.804697157%;
}
.ct-golden-section:after {
    content: "";
    display: table;
    clear: both;
}
.ct-major-sixth:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 60%;
}
.ct-minor-seventh:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 56.25%;
}
.ct-major-seventh:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 53.3333333333%;
}
.ct-octave:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 50%;
}
.ct-major-tenth:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 40%;
}
.ct-major-eleventh:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 37.5%;
}
.ct-major-twelfth:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 33.3333333333%;
}
.ct-double-octave,
.ct-major-twelfth,
.ct-major-eleventh,
.ct-major-tenth,
.ct-octave,
.ct-major-seventh,
.ct-minor-seventh,
.ct-major-sixth,
.ct-golden-section,
.ct-minor-sixth,
.ct-perfect-fifth,
.ct-perfect-fourth,
.ct-major-third,
.ct-minor-third,
.ct-major-second,
.ct-minor-second,
.ct-square {
    display: block;
    position: relative;
    width: 100%;
}
.ct-double-octave:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 25%;
}
.ct-double-octave:after,
.ct-major-twelfth:after,
.ct-major-eleventh:after,
.ct-major-tenth:after,
.ct-octave:after,
.ct-major-seventh:after,
.ct-minor-seventh:after,
.ct-major-sixth:after,
.ct-minor-sixth:after,
.ct-perfect-fifth:after,
.ct-perfect-fourth:after,
.ct-major-third:after,
.ct-minor-third:after,
.ct-major-second:after,
.ct-minor-second:after,
.ct-square:after {
    display: table;
}
.ct-double-octave > svg,
.ct-major-twelfth > svg,
.ct-major-eleventh > svg,
.ct-major-tenth > svg,
.ct-octave > svg,
.ct-major-seventh > svg,
.ct-minor-seventh > svg,
.ct-major-sixth > svg,
.ct-golden-section > svg,
.ct-minor-sixth > svg,
.ct-perfect-fifth > svg,
.ct-perfect-fourth > svg,
.ct-major-third > svg,
.ct-minor-third > svg,
.ct-major-second > svg,
.ct-minor-second > svg,
.ct-square > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
}
.ct-grid {
    stroke-width: 1px;
    stroke-dasharray: 2px;
    stroke: #777;
}
.ct-labels span {
    color: #666;
}
.ct-value span {
    color: #000;
}
.ct-legend {
    position: relative;
    z-index: 10;
    list-style: none;
    text-align: center;
}
.ct-legend li {
    position: relative;
    padding-left: 23px;
    margin-right: 10px;
    margin-bottom: 3px;
    cursor: pointer;
    display: inline-block;
}
.ct-legend li:before {
    width: 12px;
    height: 12px;
    position: absolute;
    left: 0;
    content: "";
    border: 3px solid transparent;
    border-radius: 2px;
}
.ct-legend.ct-legend-inside {
    position: absolute;
    top: 0;
    right: 0;
}
.ct-legend.ct-legend-inside li {
    display: block;
    margin: 0;
}
.ct-legend .ct-series-0:before {
    background-color: #349034;
    border-color: #349034;
}
.ct-legend .ct-series-1:before {
    background-color: #35c835;
    border-color: #35c835;
}
.ct-legend .ct-series-3:before {
    background-color: #d17905;
    border-color: #d17905;
}
.ct-legend .ct-series-4:before {
    background-color: #453d3f;
    border-color: #453d3f;
}
.ct-chart-line-multipleseries .ct-legend .ct-series-0:before {
    background-color: #0094b2;
    border-color: #0094b2;
}
.ct-series-a .ct-line,
.ct-series-a .ct-point,
.ct-series-a .ct-slice-donut {
    stroke: #349034;
}
.ct-series-b .ct-line,
.ct-series-b .ct-point,
.ct-series-b .ct-slice-donut {
    stroke: #35c835;
}
.ct-series-a .ct-bar {
    stroke: #006682;
}
.ct-series-a .ct-area {
    fill: #349034;
}
.ct-series-b .ct-area {
    fill: #35c835;
}
.ct-chart-line-multipleseries .ct-legend .ct-series-1:before,
.ct-legend .ct-series-2:before {
    background-color: #f4c63d;
    border-color: #f4c63d;
}
.ct-chart-line-multipleseries .ct-legend li.inactive:before,
.ct-legend li.inactive:before {
    background: transparent;
}
.ct-series-a .ct-slice-pie {
    fill: #d3d600;
}
.ct-series-b .ct-slice-pie {
    fill: #d6c300;
}
.ct-series-c .ct-slice-pie {
    fill: #d6b200;
}
.ct-series-d .ct-slice-pie {
    fill: #d6a200;
}
.ct-chart-pie .ct-label {
    fill: #5a5a5a;
    font-size: 1;
}
